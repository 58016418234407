import { useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import create from 'zustand';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as DownArrowImg } from '../../assets/images/down-arrow.svg';
import { profileSlide } from '../../components/Slide/ProfileSlide';
import CustomScroll from 'react-custom-scroll';

const useStore = create((set) => ({
  checked: false,
  tabIndex: 0,
  toggleCheck: () => set((prev) => ({ checked: !prev.checked, users: [], userId: null })),
  users: [],
  userId: null,
}));

export const followSlide = (checked, tabIndex, userId) => {
  useStore.setState({ checked, tabIndex, userId });
};

function a11yProps(index) {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  'textTransform': 'none',
  'fontWeight': '700',
  'fontSize': theme.typography.pxToRem(16),
  'letterSpacing': '0.1em',
  'marginRight': theme.spacing(1),
  'color': 'rgba(255, 255, 255, 0.5)',
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));

const FollowSlide = () => {
  const { checked, tabIndex, userId, users } = useStore();
  const authCtx = useContext(AuthContext);
  const type = tabIndex ? 'following' : 'followers';

  useEffect(() => {
    async function fetchUsers() {
      const response = await fetch(
        `${process.env.REACT_APP_AWS_BASE_URL}/api/user/${type}/${userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authCtx.token}`,
          },
        }
      );

      if (response.status === 403) {
        authCtx.logout();
      }

      const data = await response.json();
      useStore.setState({ users: data });
    }

    if (userId) fetchUsers();
  }, [authCtx, type, userId]);

  const tabIndexHandler = (event, newValue) => {
    useStore.setState({ tabIndex: newValue });
  };

  const closeHandler = () => {
    useStore.getState().toggleCheck();
  };

  return (
    <div className={checked ? 'slide-wrapper active' : 'slide-wrapper'}>
      <Box
        sx={{
          padding: '30px',
          zIndex: 1,
        }}
      >
        <IconButton
          className="flex-center"
          sx={{
            borderRadius: 50,
            background: '#212121',
            width: 40,
            height: 40,
            boxShadow:
              '5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25), inset -3px -3px 10px rgba(49, 49, 49, 0.25)',
          }}
          onClick={() => {
            closeHandler();
          }}
        >
          <DownArrowImg />
        </IconButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}>
          <Tabs
            value={tabIndex}
            onChange={tabIndexHandler}
            aria-label="follow unfollow tabs"
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
          >
            <StyledTab label="Followers" {...a11yProps(0)} />
            <StyledTab label="Following" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={tabIndex} index={tabIndex}>
          <CustomScroll heightRelativeToParent="calc(100%)" keepAtBottom={true}>
            <List>
              {users.map((user, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    profileSlide(true, user.id);
                  }}
                >
                  <ListItemIcon>
                    <Avatar
                      sx={{ width: 56.62, height: 56.62 }}
                      alt={user.firstName}
                      src={user.avatar}
                    />
                  </ListItemIcon>
                  <ListItemText primary={user.firstName + ' ' + user.lastName} />
                </ListItemButton>
              ))}
            </List>
          </CustomScroll>
        </TabPanel>
      </Box>
    </div>
  );
};

export default FollowSlide;
