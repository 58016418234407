import Dialog from "@mui/material/Dialog";
import create from "zustand";
import { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import FormControl from "@mui/material/FormControl";
import { InputHidden, EightTextField } from "./StationFormDialog";
import Stack from "@mui/material/Stack";
import firebaseStorageAvatar from "../../lib/firebaseStorageAvatar";
import { eightSnackBar } from "../Mui/EightSnackbar";
import Resizer from "react-image-file-resizer";
import useGlobalStore from "../../hooks/useGlobalStore";
import { Avatar } from "@mui/material";
import secureLocalStorage from "react-secure-storage";

const useStore = create((set) => ({
  dialog: false,
}));

export const userEditDialog = (dialog) => {
  useStore.setState({ dialog });
};

const UserEditDialog = (params) => {
  const defaultValues = {
    alias: params.alias,
    firstName: params.firstName,
    lastName: params.lastName,
    bio: params.bio,
  };

  const { dialog } = useStore();
  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState(defaultValues);

  const authCtx = useContext(AuthContext);

  function isValid(str) {
    return !/[ ~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const changeUpdated = useGlobalStore((state) => state.changeUpdated);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formValues.firstName.length < 3) {
      eightSnackBar(
        true,
        "First Name should have more than 3 characters.",
        "warning"
      );
      return;
    }

    if (
      isValid(formValues.firstName) === false ||
      isValid(formValues.lastName) === false
    ) {
      eightSnackBar(
        true,
        "First Name/ Last Name should not have any special characters.",
        "warning"
      );

      return;
    }

    let avatar = params.avatar;

    if (selectedFile) {
      const firebase = new firebaseStorageAvatar();
      avatar = await firebase.sendToFirebaseStorage(selectedFile, params.id);
    }

    const jsondata = {
      firstName: formValues.firstName,
      avatar: avatar,
      lastName: formValues.lastName,
      alias: formValues.alias,
      bio: formValues.bio,
    };

    let details = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify(jsondata),
    };

    await fetch(`${process.env.REACT_APP_AWS_BASE_URL}/api/user`, details);
    let user = secureLocalStorage.getItem("user");
    user.firstName = jsondata.firstName;
    user.lastName = jsondata.lastName;
    user.avatar = jsondata.avatar;
    user.alias = jsondata.alias;
    user.bio = jsondata.bio;
    secureLocalStorage.setItem("user", user);
    changeUpdated();
    closeHandler();
  };

  const closeHandler = () => {
    useStore.setState({ dialog: false });
  };

  const handleCapture = (event) => {
    if (event.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }

    new Promise((resolve) => {
      Resizer.imageFileResizer(
        event.target.files[0],
        700,
        500,
        "JPEG",
        80,
        0,
        (uri) => {
          setSelectedFile(uri);
          resolve(uri);
        },
        "file",
        700,
        500
      );
    });
  };

  return (
    <Dialog open={dialog} onClose={closeHandler} maxWidth="lg" fullWidth>
      <form className="station-form-dialog-wrp" onSubmit={handleSubmit}>
        <h2 className="align-center fw-700 ft-36">
          <span className="gradient-text">Edit user</span>
        </h2>
        <FormControl style={{ padding: "4.5rem 3.34rem" }}>
          <Stack direction="row" gap={5}>
            <div>
              <label htmlFor="upload-avatar">
                <Stack
                  sx={{
                    width: 200,
                    height: 200,
                    boxShadow:
                      "5.61297px 5.61297px 13.4711px rgba(0, 0, 0, 0.25), -5.61297px -5.61297px 11.2259px rgba(51, 51, 51, 0.25), inset -3.36778px -3.36778px 11.2259px rgba(49, 49, 49, 0.25)",
                    borderRadius: "17.96px",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  gap={3}
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <InputHidden
                    id="upload-avatar"
                    accept="image/*"
                    type="file"
                    onChange={handleCapture}
                    hidden
                  />
                  {/*imge && <img src={URL.createObjectURL(selectedFile)} height ='200px' width='200px'/>*/}
                  {!selectedFile && (
                    <Avatar
                      src={params.avatar}
                      alt={params.firstName}
                      sx={{
                        position: "absolute",
                        width: 200,
                        height: 200,
                        top: 0,
                        left: 0,
                        zIndex: 3,
                        borderRadius: "17.96px",
                      }}
                    />
                  )}
                  {selectedFile && (
                    <Avatar
                      src={URL.createObjectURL(selectedFile)}
                      alt="station avatar"
                      sx={{
                        position: "absolute",
                        width: 200,
                        height: 200,
                        top: 0,
                        left: 0,
                        zIndex: 3,
                        borderRadius: "17.96px",
                      }}
                    />
                  )}
                </Stack>
              </label>
            </div>

            <div>
              <FormControl fullWidth sx={{ mb: 1.3 }}>
                <EightTextField
                  id="alias"
                  label="Alias"
                  variant="outlined"
                  name="alias"
                  value={formValues.alias}
                  onChange={handleInputChange}
                  required={true}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl fullWidth sx={{ my: 1.3 }}>
                <EightTextField
                  id="first-name"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  required={true}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl fullWidth sx={{ my: 1.3 }}>
                <EightTextField
                  id="last-name"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  required={true}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl fullWidth sx={{ my: 1.3 }}>
                <EightTextField
                  id="bio"
                  label="bio"
                  name="bio"
                  value={formValues.bio}
                  placeholder="Tell us more about yourself"
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl fullWidth sx={{ my: 1.3, alignItems: "end" }}>
                <button
                  variant="contained"
                  className="bttn-grey width-425 ht-56 fw-500 ft-18 bttn-grey mt-35"
                  type="submit"
                >
                  <span className="gradient-text">Save</span>
                </button>
              </FormControl>
            </div>
          </Stack>
        </FormControl>
      </form>
    </Dialog>
  );
};

export default UserEditDialog;
