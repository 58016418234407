import createStationImg from '../../assets/images/create-your-station.svg';
import socializeImg from '../../assets/images/socialize.svg';
import liveAudioImg from '../../assets/images/live-audio-live-chat.svg';
import acrossBorderImg from '../../assets/images/across-borders.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const slideImages = [
  {
    url: createStationImg,
    title: 'Create Your Station',
    description: 'Create your own stations, invite your friends & start your own audio community',
  },
  {
    url: socializeImg,
    title: 'Socialize',
    description:
      'Meet new people who share your interest and indulge through audio to grow the community',
  },
  {
    url: liveAudioImg,
    title: 'Live Audio Live Chat',
    description: 'Talk to your tribe over live audio & engage in live chat like never before',
  },
  {
    url: acrossBorderImg,
    title: 'Across Borders',
    description:
      'Grow your fan base by reaching new audiences across borders',
  },
];

const CustomDot = ({ onClick, active }) => {
  return (
    <li className={active && 'active'}>
      <button onClick={() => onClick()} />
    </li>
  );
};

const sliderSettings = {
  arrows: false,
  draggable: false,
  swipeable: false,
  shouldResetAutoplay: false,
  responsive: {
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      slidesToSlide: 1,
      items: 1,
    },
  },
  renderDotsOutside: <CustomDot />,
};

const EightHomeSlider = () => {
  return (
    <Carousel showDots {...sliderSettings}>
      {slideImages.map((slideImage, index) => (
        <div className="slide-item" key={index}>
          <img alt={slideImage.title} src={slideImage.url} />
          <h2 className="mt-50 mb-18 title">{slideImage.title}</h2>
          <p className="ft-md">{slideImage.description}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default EightHomeSlider;
