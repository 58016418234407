import create from 'zustand';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { ReactComponent as BackArrowImg } from '../../assets/images/back-arrow.svg';
import FetchUserDetail3 from '../../api/user/FetchUserDetail3';
import CustomScroll from 'react-custom-scroll';

const useStore = create((set) => ({
  checked: false,
  toggleCheck: () => set((prev) => ({ checked: !prev.checked, users: [], userId: null })),
  users: [],
  userId: null,
}));

export const profileSlide = (checked, userId) => {
  useStore.setState({ checked, userId });
};

const ProfileSlide = () => {
  const { checked, userId } = useStore();

  const closeHandler = () => {
    useStore.getState().toggleCheck();
  };

  return (
    <div className={checked ? 'slide-wrapper active' : 'slide-wrapper'}>
      <CustomScroll heightRelativeToParent="calc(100%)" keepAtBottom={true}>
        <Box
          sx={{
            padding: '30px 30px 0 30px',
            zIndex: 1,
          }}
        >
          <IconButton
            className="flex-center"
            sx={{
              borderRadius: 50,
              background: '#212121',
              width: 40,
              height: 40,
              boxShadow:
                '5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25), inset -3px -3px 10px rgba(49, 49, 49, 0.25)',
            }}
            onClick={() => {
              closeHandler();
            }}
          >
            <BackArrowImg />
          </IconButton>
        </Box>
        {userId && <FetchUserDetail3 userId={userId} />}
      </CustomScroll>
    </div>
  );
};

export default ProfileSlide;
