import { Fragment, useState, useContext, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import EightHomeSlider from "../components/Slide/EightHomeSlider";
import AuthContext from "../store/auth-context";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
// import Login from "../auth/Login";
import { ReactComponent as FbImg } from "../assets/images/fb.svg";
import { ReactComponent as TwImg } from "../assets/images/tw.svg";
import { ReactComponent as InstImg } from "../assets/images/inst.svg";
import GPlayImg from "../assets/images/google-play-badge.png";
import { Stack } from "@mui/material";
import { isMobile } from "react-device-detect"; //osName, browserName,
import DeviceDetectDialog from "../components/Dialog/DeviceDetectDialog";
import LoginForm from "../auth/LoginForm";
import MainHeader from "../components/Navigation/MainHeader";
const QRCode = require("qrcode.react");

const AuthPage = () => {
  const [connID, setConnID] = useState();
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const { sendJsonMessage, lastMessage } = useWebSocket(
    process.env.REACT_APP_AWS_QR_SOCKET_URL
  );

  useEffect(() => {
    async function fetchUser(token) {
      const response = await fetch(
        `${process.env.REACT_APP_AWS_BASE_URL}/api/user/self`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      authCtx.login({ ...data, token });
      history.replace("/");

      return;
    }

    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      if (data.type === "qr_connect") {
        setConnID(data.body);
      }

      if (data.type === "auth_success" && data.body) {
        fetchUser(data.body);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    sendJsonMessage({
      action: "sck-fetch-conn-id",
      message: "qrCode",
    });
  }, []);

  return (
    <Fragment>
      {isMobile ? (
        <DeviceDetectDialog />
      ) : (
        <Fragment>
          <MainHeader />
          <div className="auth-page-container">
            <div className="download-app bg-cl-3 radius-13">
              <LoginForm />
            </div>
            <div className="pd-l-r-40 bg-cl-3 radius-13 home-slider-wrp">
              <EightHomeSlider />
            </div>
            <div className="grid-block-1 qr-image bg-cl-3 radius-13">
              <h3>Scan the code to use Eight studio</h3>
              <div className="qr-image-wrapper">
                {connID && (
                  <QRCode
                    renderAs="svg"
                    style={{ width: 256, height: 256 }}
                    bgColor="#212121"
                    fgColor="#fff"
                    value={connID}
                  />
                )}
              </div>
              <div>
                <label className="form-control">
                  <input type="checkbox" name="checkbox" />
                  Keep me signed in
                </label>
              </div>
            </div>
          </div>
          <footer className="bg-cl-3 mt-20">
            <h2 className="title">The future of Radio.</h2>
            <p className="lt-grey-1 mt-10 mb-20">
              Eight network creations private limited
            </p>
            <div>
              <Link
                href="https://play.google.com/store/apps/details?id=com.network.eight.android&hl=en&gl=US"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                <img
                  style={{ height: 75 }}
                  src={GPlayImg}
                  alt="eight studio google play badge"
                />
              </Link>
            </div>
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                margin: "2rem auto 3.35rem auto",
                width: "100%",
                minWidth: "40rem",
                maxWidth: "60rem",
                padding: "0 3rem",
                transform: "translateX(-1.6rem)",
              }}
            >
              <Link
                href="https://www.eight.network/community-guidelines"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                Company Guidelines
              </Link>
              <Link
                href="https://www.eight.network/terms-and-conditions"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                Terms & Conditions
              </Link>
              <Link
                href="https://www.eight.network/privacy-policy"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                Privacy Policy
              </Link>
            </Stack>

            {/* <Login /> */}

            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              gap={5}
            >
              <Link
                href="https://www.facebook.com/eightnetwork/"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                <FbImg />
              </Link>
              <Link
                href="https://twitter.com/TheEightNetwork"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                <TwImg />
              </Link>
              <Link
                href="https://www.instagram.com/eight_network"
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}
              >
                <InstImg />
              </Link>
            </Stack>
          </footer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AuthPage;
