import { Fragment, useContext } from "react";
import CustomScroll from "react-custom-scroll";
import FetchScheduledStationList from "../api/station/FetchScheduledStationList";
import FetchStationList from "../api/station/FetchStationList";
import FetchUserDetail1 from "../api/user/FetchUserDetail1";
import ProfileSettings from "../components/Profile/ProfileSettings";
import FollowSlide from "../components/Slide/FollowSlide";
import ProfileSlide from "../components/Slide/ProfileSlide";
import AuthContext from "../store/auth-context";
import ScheduledStationDialog from "../components/Dialog/ScheduledStationDialog";
import { ReactComponent as MicLargeImg } from "../assets/images/mic-large.svg";
import { Button, Stack } from "@mui/material";
import StationFormDialog, {
  stationFormDialog,
} from "../components/Dialog/StationFormDialog";
import secureLocalStorage from "react-secure-storage";
import MainHeader from "../components/Navigation/MainHeader";

const ProfilePage = () => {
  const authCtx = useContext(AuthContext);
  const stationCount = secureLocalStorage.getItem("stationCount");

  return (
    <Fragment>
      <MainHeader />
      <div
        className={
          stationCount > 0
            ? "profile-page-container"
            : "profile-page-container col-layout"
        }
      >
        <Stack className="profile-page-sidebar" sx={{ overflow: "hidden" }}>
          <div className="user-detail-wrapper-1">
            <CustomScroll
              heightRelativeToParent="calc(100%)"
              keepAtBottom={true}
            >
              <FetchUserDetail1 userId={authCtx.user.id} />
              <ProfileSettings />
            </CustomScroll>
            <FollowSlide />
            <ProfileSlide />
          </div>
        </Stack>
        {stationCount > 0 ? (
          <Fragment>
            <div className="station-profile-wrapper station-profile-mr">
              <div className="mt-45">
                <FetchStationList
                  isProfile={true}
                  slideSetting={{
                    responsive: {
                      superLargeDesktop: {
                        // the naming can be any, depends on you.
                        breakpoint: { max: 3000, min: 1400 },
                        partialVisibilityGutter: 30,
                        slidesToSlide: 5,
                        items: 5,
                      },
                      desktop: {
                        breakpoint: { max: 1399, min: 1200 },
                        partialVisibilityGutter: 25,
                        slidesToSlide: 4,
                        items: 4,
                      },
                      tablet: {
                        breakpoint: { max: 1199, min: 995 },
                        partialVisibilityGutter: 25,
                        slidesToSlide: 3,
                        items: 3,
                      },
                      mobile: {
                        breakpoint: { max: 994, min: 0 },
                        partialVisibilityGutter: 50,
                        slidesToSlide: 2,
                        items: 2,
                      },
                    },
                    arrows: false,
                  }}
                  userId={authCtx.user.id}
                />
              </div>
            </div>
            <div className="scheduled-station-wrapper station-profile-mr">
              <h4 className="mb-20">My Scheduled shows</h4>
              <FetchScheduledStationList
                slideSetting={{
                  responsive: {
                    superLargeDesktop: {
                      // the naming can be any, depends on you.
                      breakpoint: { max: 3000, min: 1750 },
                      partialVisibilityGutter: 30,
                      slidesToSlide: 3,
                      items: 3,
                    },
                    desktop: {
                      breakpoint: { max: 1749, min: 1500 },
                      partialVisibilityGutter: 120,
                      slidesToSlide: 2,
                      items: 2,
                    },
                    tablet: {
                      breakpoint: { max: 1499, min: 1230 },
                      partialVisibilityGutter: 25,
                      slidesToSlide: 1,
                      items: 2,
                    },
                    mobile: {
                      breakpoint: { max: 1229, min: 0 },
                      partialVisibilityGutter: 0,
                      slidesToSlide: 1,
                      items: 1,
                    },
                  },
                  arrows: false,
                }}
              />
            </div>
            <ScheduledStationDialog />
            <StationFormDialog />
          </Fragment>
        ) : (
          <Fragment>
            <Stack alignItems="center" justifyContent="center">
              <Stack
                maxWidth={283}
                direction="column"
                alignItems="center"
                gap={4}
              >
                <MicLargeImg />
                <Stack gap={0.8} textAlign="center">
                  <h3 className="ft-24 fw-500">No station yet!</h3>
                  <p className="grey-lt-1">
                    It seems like you haven’t created any stations , start
                    creating now!
                  </p>
                </Stack>
                <Button
                  className="bttn-gradient ft-16 fw-500 width-100 ht-56"
                  onClick={() => {
                    stationFormDialog(true);
                  }}
                  autoFocus
                >
                  Create a station
                </Button>
              </Stack>
            </Stack>
            <StationFormDialog />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ProfilePage;
