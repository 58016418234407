import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import EditImg from "../../assets/images/edit.svg";
import { userEditDialog } from "../../components/Dialog/UserEditDialog";

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   '& .MuiBadge-badge': {
//     'backgroundColor': '#44b700',
//     'color': '#44b700',
//     'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
//     '&::after': {
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       borderRadius: '50%',
//       animation: 'ripple 1.2s infinite ease-in-out',
//       border: '1px solid currentColor',
//       content: '""',
//     },
//   },
//   '@keyframes ripple': {
//     '0%': {
//       transform: 'scale(.8)',
//       opacity: 1,
//     },
//     '100%': {
//       transform: 'scale(2.4)',
//       opacity: 0,
//     },
//   },
// }));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 39.85,
  height: 39.85,
  border: `2px solid #212121`,
}));

const AvatarPic = ({ user }) => {
  return (
    <div className="avatar-outlay">
      <Avatar
        sx={{ width: 140, height: 140 }}
        alt={user.firstName}
        src={user.avatar}
      />
    </div>
  );
};

export default function EightProfileEditBadge({ user }) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <button
          className="bttn-transparent"
          onClick={() => userEditDialog(true)}
        >
          <SmallAvatar
            className="auto-img-dim edit-profile-badge"
            alt="Edit Profile"
            src={EditImg}
          />
        </button>
      }
    >
      <AvatarPic user={user} />
    </Badge>
  );
}
