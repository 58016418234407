import { useEffect } from "react";
import { eightSnackBar } from "../components/Mui/EightSnackbar";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudQueueRoundedIcon from "@mui/icons-material/CloudQueueRounded";

// const getOnLineStatus = () =>
//   typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
//     ? navigator.onLine
//     : true;

const useOffline = () => {
  // const [state, setState] = useState(getOnLineStatus());
  const onlineHandler = () => {
    eightSnackBar(
      true,
      "Connecting...",
      "warning",
      1000,
      <CloudQueueRoundedIcon fontSize="inherit" />
    );
  };

  const offlineHandler = () => {
    eightSnackBar(
      true,
      "You're Offline. Make sure your computer has an active internet connection.",
      "error",
      null,
      <CloudOffIcon fontSize="inherit" />
    );
  };

  useEffect(() => {
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);
};

export default useOffline;
