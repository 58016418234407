import Button from '@mui/material/Button';
import PreSessionInit from '../../../lib/PreSessionInit';
import { useHistory } from 'react-router-dom';
import { goLiveDialog } from '../../Dialog/GoLiveDialog';
import { eightBackdrop } from '../../Backdrop/EightBackdrop';
import { useContext } from 'react';
import AuthContext from '../../../store/auth-context';

const GoLiveButton = (props) => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const preSessionStartHandler = async () => {
    eightBackdrop(true);
    const presession = new PreSessionInit(authCtx, props.station);
    await presession._init();
    goLiveDialog(false, null); // close dialog
    eightBackdrop(false);
    history.replace('/session');
  };

  return (
    <Button
      className="bttn-gradient ft-18 fw-500 width-100 ht-56"
      onClick={preSessionStartHandler}
      autoFocus
    >
      Go live
    </Button>
  );
};

export default GoLiveButton;
