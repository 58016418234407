import Dialog from "@mui/material/Dialog";
import create from "zustand";
import { useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { InputHidden, EightTextField } from "./StationFormDialog";
import Stack from "@mui/material/Stack";
import firebaseStorage from "../../lib/firebaseStorage";
import EightSnackbar from "../Mui/EightSnackbar";
import { goLiveDialog } from "./GoLiveDialog";
import Resizer from "react-image-file-resizer";
import useGlobalStore from "../../hooks/useGlobalStore";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as BackArrowImg } from "../../assets/images/back-arrow.svg";

const useStore = create((set) => ({
  dialog: false,
}));

export const stationEditDialog = (dialog) => {
  useStore.setState({ dialog });
};

const StationEditDialog = (params) => {
  const defaultValues = {
    name: params.name,
    description: params.description,
    aboutSchedule: params.aboutSchedule,
  };

  const { dialog } = useStore();
  const [tags, setTags] = useState(params.tags);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState(defaultValues);
  const [snackbarOpen, setSnackBarOpen] = useState(false);

  const authCtx = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const changeUpdated = useGlobalStore((state) => state.changeUpdated);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let banner = params.banner;

    if (selectedFile) {
      const firebase = new firebaseStorage();
      banner = await firebase.sendToFirebaseStorage(
        selectedFile,
        formValues.name
      );
    }

    const jsondata = {
      name: formValues.name,
      banner: banner,
      description: formValues.description,
      tags: tags,
    };

    let details = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify(jsondata),
    };

    fetch(
      `${process.env.REACT_APP_AWS_BASE_URL}/api/station/${params.id}`,
      details
    );
    setSnackBarOpen(true);
    closeHandler();
  };

  const handleBack = () => {
    stationEditDialog(false);
  };

  const closeHandler = () => {
    useStore.setState({ dialog: false });
    goLiveDialog(false);
    changeUpdated();
  };

  const handleCapture = (event) => {
    if (event.target.files.length === 0) {
      setSelectedFile(null);
      return;
    }

    new Promise((resolve) => {
      Resizer.imageFileResizer(
        event.target.files[0],
        700,
        500,
        "JPEG",
        80,
        0,
        (uri) => {
          setSelectedFile(uri);
          resolve(uri);
        },
        "file",
        700,
        500
      );
    });
  };

  return (
    <Dialog open={dialog} onClose={closeHandler} maxWidth="lg" fullWidth>
      <div className="station-form-dialog-wrp">
        <Stack
          className="mb-20"
          direction="row"
          spacing={5}
          alignItems="center"
        >
          <IconButton
            className="flex-center"
            sx={{
              borderRadius: 50,
              background: "#212121",
              width: 40,
              height: 40,
              boxShadow:
                "5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25), inset -3px -3px 10px rgba(49, 49, 49, 0.25)",
            }}
            onClick={handleBack}
          >
            <BackArrowImg />
          </IconButton>
          <h2 className="align-center fw-700 ft-36">
            <span className="gradient-text">Edit station</span>
          </h2>
        </Stack>

        <form onSubmit={handleSubmit}>
          <FormControl style={{ padding: "2.5rem 3.34rem  0 3.34rem" }}>
            <Stack direction="row" gap={5}>
              <div>
                <label htmlFor="upload-banner">
                  <Stack
                    sx={{
                      width: 200,
                      height: 200,
                      boxShadow:
                        "5.61297px 5.61297px 13.4711px rgba(0, 0, 0, 0.25), -5.61297px -5.61297px 11.2259px rgba(51, 51, 51, 0.25), inset -3.36778px -3.36778px 11.2259px rgba(49, 49, 49, 0.25)",
                      borderRadius: "17.96px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    gap={3}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <InputHidden
                      id="upload-banner"
                      accept="image/*"
                      type="file"
                      onChange={handleCapture}
                      hidden
                    />
                    {/*imge && <img src={URL.createObjectURL(selectedFile)} height ='200px' width='200px'/>*/}
                    {!selectedFile && (
                      <Avatar
                        src={params.banner}
                        alt={params.name}
                        sx={{
                          position: "absolute",
                          width: 200,
                          height: 200,
                          top: 0,
                          left: 0,
                          zIndex: 3,
                          borderRadius: "17.96px",
                        }}
                      />
                    )}
                    {selectedFile && (
                      <Avatar
                        src={URL.createObjectURL(selectedFile)}
                        alt="station banner"
                        sx={{
                          position: "absolute",
                          width: 200,
                          height: 200,
                          top: 0,
                          left: 0,
                          zIndex: 3,
                          borderRadius: "17.96px",
                        }}
                      />
                    )}
                  </Stack>
                </label>
              </div>

              <div>
                <FormControl fullWidth sx={{ mb: 1.3 }}>
                  <EightTextField
                    id="station-name"
                    label="Station Name"
                    variant="outlined"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    required={true}
                    autoComplete="off"
                  />
                </FormControl>
                <FormControl fullWidth sx={{ my: 1.3 }}>
                  <EightTextField
                    id="station-about"
                    label="About Station"
                    name="description"
                    value={formValues.description}
                    placeholder="Tell us more about station"
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ my: 1.3 }}>
                  <Autocomplete
                    className="gradient-tags"
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={params.tags}
                    freeSolo
                    onChange={(e, value) => setTags((state) => value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <EightTextField
                        {...params}
                        label="Tags"
                        placeholder="Add tags"
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ my: 1.3, alignItems: "end" }}>
                  <button
                    variant="contained"
                    className="bttn-grey width-425 ht-56 fw-500 ft-18 bttn-grey mt-35"
                    type="submit"
                  >
                    <span className="gradient-text">Save Station</span>
                  </button>
                </FormControl>
              </div>
            </Stack>
          </FormControl>
          <EightSnackbar
            open={snackbarOpen}
            message="Station edited successfully !!"
          />
        </form>
      </div>
    </Dialog>
  );
};

export default StationEditDialog;
