import { Fragment, useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Avatar, Stack, Typography } from '@mui/material';
import { ReactComponent as VisibleImg } from '../../assets/images/visible.svg';
import { ReactComponent as HiddenImg } from '../../assets/images/hidden.svg';
import { ReactComponent as CalendarImg } from '../../assets/images/calendar.svg';
import { scheduledStationDialog } from '../../components/Dialog/ScheduledStationDialog';
import EightCarouselButton from '../../components/Mui/EightCarouselButton';
import EightLoader from '../../components/Backdrop/EightLoader';
import useGlobalStore from "../../hooks/useGlobalStore";

const { format } = require('date-fns');

const FetchScheduledStationList = (props) => {
  const authCtx = useContext(AuthContext);
  const [stations, setStations] = useState(null);
  const isUpdated = useGlobalStore((state) => state.isUpdated);

  const sliderSettings = {
    ...props.slideSetting,
    showDots: props.dots ? true : false,
    draggable: false,
    swipeable: false,
    shouldResetAutoplay: false,
    partialVisible: true,
  };

  useEffect(() => {
    async function fetchStations() {
      const response = await fetch(
        `${process.env.REACT_APP_AWS_BASE_URL}/api/station/scheduled/user`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.token}`,
          },
        }
      );

      if (response.status === 403) {
        authCtx.logout();
      }

      const data = await response.json();
      setStations(data);
    }

    fetchStations();
  }, [authCtx, props.userId, isUpdated]);

  return (
    <div className="slide-container station-slider">
      {stations !== null && stations.length !== 0 && (
        <Carousel
          renderButtonGroupOutside={true}
          customButtonGroup={<EightCarouselButton />}
          {...sliderSettings}
        >
          {stations.map((station, index) => (
            <Stack
              direction="row"
              alignContent="center"
              alignItems="center"
              gap={2.2}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                scheduledStationDialog(true, station);
              }}
              key={index}
              sx={{
                background: '#2A2A2A',
                borderRadius: '16px',
                padding: '1rem',
                maxWidth: '360px',
              }}
            >
              <Avatar
                sx={{ width: 95, height: 95, borderRadius: '12px' }}
                alt={station.name}
                src={station.banner}
              />
              <Stack direction="column" gap={0.5} sx={{ color: 'rgba(255, 255, 255, 0.5)' }}>
                <Typography sx={{ overflow: 'hidden', height: 27, color: '#fff', fontWeight: 500 }}>
                  {station.name}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CalendarImg />
                  <span>{format(new Date(station.scheduledOn), 'dd MMM yyyy, h:mm a')}</span>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  {station.isPrivate ? (
                    <Fragment>
                      <HiddenImg />
                      Private
                    </Fragment>
                  ) : (
                    <Fragment>
                      <VisibleImg />
                      Public
                    </Fragment>
                  )}
                </Stack>
                {/* <h5 className="mt-15" data-id={station.id}>
             {station.name}
           </h5> */}
              </Stack>
            </Stack>
          ))}
        </Carousel>
      )}
      {stations === null && <EightLoader />}
      {stations && stations.length === 0 && (
        <Stack
          p={2}
          sx={{
            color: 'rgba(255, 255, 255, 0.2)',
            fontSize: '1rem',
            fontWeight: 600,
            textAlign: 'center',
            margin: '35px auto',
          }}
        >
          You have not scheduled any stations at the moment
        </Stack>
      )}
    </div>
  );
};

export default FetchScheduledStationList;
