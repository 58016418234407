import { useState, createContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const AuthContext = createContext({
  token: "",
  isLoggedIn: false,
  user: {},
  login: (data) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});
  const userIsLoggedIn = !!token;

  const loginHandler = (data) => {
    secureLocalStorage.setItem("token", data.token);
    secureLocalStorage.setItem("user", data);

    setUser(data);
    setToken(data.token);
  };

  const logoutHandler = () => {
    setToken(null);
    setUser(null);
    secureLocalStorage.clear();
  };

  const contextValue = {
    token,
    user,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  useEffect(() => {
    const initToken = secureLocalStorage.getItem("token");
    const initUser = secureLocalStorage.getItem("user");

    setToken(initToken);
    setUser(initUser);
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
