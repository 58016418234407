import create from "zustand";

const useGlobalStore = create((set) => ({
  isUpdated: false,
  stationBannerURL: " ",

  updateStationBannerURL: (data) =>
    set(
      (state) => ({
        stationBannerURL: data,
      }),
      console.log(data, "zustand working fine")
    ),

  changeUpdated: () => {
    set((state) => ({ isUpdated: !state.isUpdated }));
    // console.log("working");
  },
}));

export default useGlobalStore;
