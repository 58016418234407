import { useContext } from 'react';
import AuthContext from '../../store/auth-context';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ReactComponent as RightArrowImg } from '../../assets/images/right-arrow-1.svg';

const ProfileSettings = () => {
  const authCtx = useContext(AuthContext);
  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <List className="mt-50" sx={{ borderTop: '8px solid #2B2B2B', paddingBottom: '5rem' }}>
      <ListItemButton
        component="a"
        href="https://www.eight.network/community-guidelines"
        target="_blank"
        rel="noopener"
        sx={{ pl: 6 }}
      >
        <ListItemText primary="Community Guidelines" />
        <ListItemIcon sx={{ pl: 4 }}>
          <RightArrowImg />
        </ListItemIcon>
      </ListItemButton>
      <Divider sx={{ borderColor: '#2B2B2B' }} />
      <ListItemButton
        component="a"
        target="_blank"
        rel="noopener"
        href="https://www.eight.network/privacy-policy"
        sx={{ pl: 6 }}
      >
        <ListItemText primary="Privacy Policies" />
        <ListItemIcon sx={{ pl: 4 }}>
          <RightArrowImg />
        </ListItemIcon>
      </ListItemButton>
      <Divider sx={{ borderColor: '#2B2B2B' }} />
      <ListItemButton
        component="a"
        href="https://www.eight.network/terms-and-conditions"
        target="_blank"
        rel="noopener"
        sx={{ pl: 6 }}
      >
        <ListItemText primary="Terms & Conditions" />
        <ListItemIcon sx={{ pl: 4 }}>
          <RightArrowImg />
        </ListItemIcon>
      </ListItemButton>
      <Divider sx={{ borderColor: '#2B2B2B' }} />
      <ListItemButton
        component="a"
        target="_blank"
        rel="noopener"
        href="https://www.eight.network/contact"
        sx={{ pl: 6 }}
      >
        <ListItemText primary="Contact Us" />
        <ListItemIcon sx={{ pl: 4 }}>
          <RightArrowImg />
        </ListItemIcon>
      </ListItemButton>
      <Divider sx={{ borderColor: '#2B2B2B' }} />
      <ListItemButton sx={{ pl: 6 }} onClick={logoutHandler}>
        <ListItemText primary="Logout" />
        <ListItemIcon sx={{ pl: 4 }}>
          <RightArrowImg />
        </ListItemIcon>
      </ListItemButton>
    </List>
  );
};

export default ProfileSettings;
