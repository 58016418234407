import { useContext } from "react";
import AuthContext from "./store/auth-context";
import { createTheme, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import EightBackdrop from "./components/Backdrop/EightBackdrop";
import EightSnackbar from "./components/Mui/EightSnackbar";
import useOffline from "./hooks/useOffline";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

const theme = createTheme({
  palette: {
    primary: { main: "#ffffff" },
    secondary: { main: "rgba(255, 255, 255, 0.5)" },
    type: "dark",
    background: {
      paper: "#212121",
    },
  },
  typography: {
    fontFamily: ["SF Pro Display", "sans-serif"].join(","),
  },
});

function App() {
  const authCtx = useContext(AuthContext);
  useOffline();

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Router>
          {!authCtx.isLoggedIn && <PublicRoute />}
          {authCtx.isLoggedIn && <PrivateRoute />}
        </Router>
        <EightBackdrop />
        <EightSnackbar />
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
