import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import GoLiveDialog, {
  goLiveDialog,
} from "../../components/Dialog/GoLiveDialog";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EightCarouselButton from "../../components/Mui/EightCarouselButton";
import EightLoader from "../../components/Backdrop/EightLoader";
import useGlobalStore from "../../hooks/useGlobalStore";
import { stationFormDialog } from "../../components/Dialog/StationFormDialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";

const FetchStationList = (props) => {
  const authCtx = useContext(AuthContext);
  const [stations, setStations] = useState(null);
  const isUpdated = useGlobalStore((state) => state.isUpdated);

  const sliderSettings = {
    ...props.slideSetting,
    showDots: props.dots ? true : false,
    draggable: false,
    swipeable: false,
    shouldResetAutoplay: false,
    partialVisible: true,
  };

  useEffect(() => {
    async function fetchStations() {
      const response = await fetch(
        `${process.env.REACT_APP_AWS_BASE_URL}/api/station/user/${props.userId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authCtx.token}`,
          },
        }
      );

      if (response.status === 403) {
        authCtx.logout();
      }

      const data = await response.json();
      setStations(data);
      secureLocalStorage.setItem("stationCount", data.length);
    }

    fetchStations();
  }, [authCtx, props.userId, isUpdated]);

  return (
    <Fragment>
      {stations !== null && stations.length !== 0 && (
        <Fragment>
          <Stack
            className="mb-20"
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <h4>My Stations</h4>
            {props.isProfile && (
              <Button
                onClick={() => {
                  stationFormDialog(true);
                }}
                className="transparent-bttn"
              >
                <span className="gradient-text">+ Add New</span>
              </Button>
            )}
          </Stack>
          <div className="slide-container station-slider">
            <Carousel
              renderButtonGroupOutside={true}
              customButtonGroup={<EightCarouselButton />}
              {...sliderSettings}
            >
              {stations.map((station, index) => (
                <div key={index}>
                  <button
                    onClick={() => {
                      goLiveDialog(true, station);
                    }}
                    className="bttn-transparent"
                  >
                    <img
                      src={station.banner}
                      alt={station.name}
                      data-id={station.id}
                    />
                    {station.isPrivate && (
                      <span className="gradient-badge fw-700">Private</span>
                    )}

                    {/* <h5 className="mt-15" data-id={station.id}>
                    {station.name}
                  </h5> */}
                  </button>
                </div>
              ))}
            </Carousel>
          </div>
          <GoLiveDialog />
        </Fragment>
      )}
      {stations === null && (
        <div className="slide-container mt-50 station-slider">
          <EightLoader />
        </div>
      )}
    </Fragment>
  );
};

export default FetchStationList;
