import { forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import create from "zustand";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

const useStore = create((set) => ({
  open: false,
  severity: "success",
  autoHide: 6000,
  icon: <CheckCircleOutlinedIcon fontSize="inherit" />,
  message: null,
}));

export const eightSnackBar = (open, message, severity, autoHide, icon) => {
  useStore.setState({ open, message, severity, autoHide, icon });
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EightSnackbar = () => {
  const { open, message, severity, autoHide, icon } = useStore();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    useStore.setState({ open: false });
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={autoHide} onClose={handleClose}>
        <Alert
          icon={icon}
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default EightSnackbar;
