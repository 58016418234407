import secureLocalStorage from "react-secure-storage";
class PreSessionInit {
  constructor(authCtx, station) {
    this.authCtx = authCtx;
    this._options = {
      appId: process.env.REACT_APP_AGORA_APP_ID,
      channel: station.id,
      rtc: null,
      rtm: null,
      station,
    };
  }

  async fetchRtmToken() {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_BASE_URL}/api/v1/fetch/rtm/token?userId=${this._options.station.owner.id}&stationId=${this._options.station.id}&role=publisher`
    );

    if (response.status === 403) {
      this.authCtx.logout();
    }

    const data = await response.json();
    this._options.rtm = data.token;
  }

  async fetchRtcToken() {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_BASE_URL}/api/fetch/rtc/token?channelName=${this._options.station.id}&role=publisher`
    );

    if (response.status === 403) {
      this.authCtx.logout();
    }

    const data = await response.json();
    this._options.rtc = data.token;
  }

  async _init() {
    await this.fetchRtcToken();
    await this.fetchRtmToken();
    secureLocalStorage.setItem("sessionConfig", this._options);
  }
}

export default PreSessionInit;
