import { Dialog, Button } from "@mui/material";
import create from "zustand";
import { Fragment, useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EightSwitch from "../Mui/EightSwitch";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Resizer from "react-image-file-resizer";
import firebaseStorage from "../../lib/firebaseStorage";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CameraImg } from "../../assets/images/camera.svg";
import { styled } from "@mui/material/styles";
import { Avatar, Typography } from "@mui/material";
import { createTheme } from "@mui/material";
import Schedular from "../schedular";
import { eightSnackBar } from "../Mui/EightSnackbar";
import PreSessionInit from "../../lib/PreSessionInit";
import { useHistory } from "react-router-dom";
import { eightBackdrop } from "../../components/Backdrop/EightBackdrop";
import CustomScroll from "react-custom-scroll";
import useGlobalStore from "../../hooks/useGlobalStore";

export const InputHidden = styled("input")({
  display: "none",
});

const useStore = create((set) => ({
  dialog: false,
}));

export const stationFormDialog = (dialog) => {
  useStore.setState({ dialog });
};

export const theme = createTheme({
  palette: {
    primary: { main: "#007FFF" },
    secondary: { main: "#000" },
    background: {
      paper: "#fff",
    },
  },
});

export const EightTextField = styled(TextField)({
  boxShadow:
    "3.82371px 3.82371px 3.82371px rgba(27, 27, 27, 0.25), -1.91185px -1.91185px 4.77964px rgba(37, 37, 37, 0.25), inset 3.82371px 3.82371px 9.55927px rgba(10, 10, 10, 0.25), inset -5.73556px -5.73556px 9.55927px rgba(49, 48, 48, 0.25)",
  borderRadius: "11.4711px",
  borderColor: "transparent",
  outline: "none",
  "& label": {
    color: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-input ": {
    color: "#fff",
  },
});

export const PrivateStationLabel = () => {
  return (
    <div>
      <span>Is this station private?</span>
      <Typography sx={{ mt: 0.5, color: "rgba(255, 255, 255, 0.2)" }}>
        Limit Attendees to your circle of friends or community
      </Typography>
    </div>
  );
};

export const ScheduleStationLabel = () => {
  return (
    <div>
      <span>Do you want to schedule the station</span>
      <Typography sx={{ mt: 0.5, color: "rgba(255, 255, 255, 0.2)" }}>
        Schedule a show for a later date
      </Typography>
    </div>
  );
};

const StationFormDialog = () => {
  const { dialog } = useStore();
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonType, setButtonType] = useState(null);
  const [isScheduled, setIsScheduled] = useState("false");
  const [isPrivate, setIsPrivate] = useState(false);
  const [scheduledOn, setScheduledOn] = useState(new Date());
  const [tags, setTags] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    aboutSchedule: "",
  });
  const authCtx = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const changeUpdated = useGlobalStore((state) => state.changeUpdated);

  const storeData = async () => {
    const firebase = new firebaseStorage();
    const banner = await firebase.sendToFirebaseStorage(
      selectedFile,
      formValues.name
    );
    const data = {
      id: uuidv4(),
      name: formValues.name,
      banner,
      aboutSchedule: formValues.aboutSchedule,
      description: formValues.description,
      scheduledOn: scheduledOn.getTime(),
      isPrivate: isPrivate,
      isScheduled: isScheduled,
      tags: tags,
    };

    const response = await fetch(
      `${process.env.REACT_APP_AWS_BASE_URL}/api/station`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${authCtx.token}`,
        },
      }
    );

    if (response.status === 403) {
      authCtx.logout();
    }

    await response.text();

    return data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    eightBackdrop(true);

    if (isSubmitted) {
      return;
    }

    setIsSubmitted(true);
    const data = await storeData();

    if (buttonType === 1) {
      closeHandler();
      eightBackdrop(false);
      eightSnackBar(true, "Station created successfully !!");
    }

    if (buttonType === 2) {
      //call go live
      data.owner = authCtx.user;
      const presession = new PreSessionInit(authCtx, data);
      await presession._init();

      closeHandler();
      eightBackdrop(false);
      history.replace("/session");
    }
  };

  const closeHandler = () => {
    useStore.setState({ dialog: false });
    setSelectedFile(null);
    changeUpdated();

    if (isScheduled === "true") {
      setIsScheduled("false");
      formValues.aboutSchedule = "";
      setScheduledOn(0);
    }
  };

  const handleSchedular = () => {
    if (isScheduled === "true") {
      setIsScheduled("false");
      formValues.aboutSchedule = "";
      setScheduledOn(0);
    }
    if (isScheduled === "false") {
      setIsScheduled("true");
      setIsPrivate(false);
    }
  };

  const handlePvtStn = () => {
    setIsPrivate(!isPrivate);
    if (isPrivate) {
      setIsScheduled("false");
      formValues.aboutSchedule = "";
      setScheduledOn(0);
    }
  };

  const handleScheduledOn = (newValue) => {
    setScheduledOn(newValue);
  };

  const handleCapture = (event) => {
    if (event.target.files.length === 0) return;

    new Promise((resolve) => {
      Resizer.imageFileResizer(
        event.target.files[0],
        700,
        500,
        "JPEG",
        80,
        0,
        (uri) => {
          setSelectedFile(uri);
          resolve(uri);
        },
        "file",
        700,
        500
      );
    });
  };

  return (
    <Dialog open={dialog} onClose={closeHandler} maxWidth="lg" fullWidth>
      <div className="eight-dialog-overflow">
        <CustomScroll heightRelativeToParent="calc(100%)" keepAtBottom={true}>
          <form className="station-form-dialog-wrp" onSubmit={handleSubmit}>
            <h2 className="align-center fw-700 ft-36">
              <span className="gradient-text">Create new station</span>
            </h2>
            <FormControl style={{ padding: "4.5rem 3.34rem" }}>
              <Stack direction="row" gap={5}>
                <div>
                  <label htmlFor="upload-banner">
                    <Stack
                      sx={{
                        width: 200,
                        height: 200,
                        boxShadow:
                          "5.61297px 5.61297px 13.4711px rgba(0, 0, 0, 0.25), -5.61297px -5.61297px 11.2259px rgba(51, 51, 51, 0.25), inset -3.36778px -3.36778px 11.2259px rgba(49, 49, 49, 0.25)",
                        borderRadius: "17.96px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      gap={3}
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <InputHidden
                        id="upload-banner"
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleCapture}
                        hidden
                      />
                      {selectedFile && (
                        <Avatar
                          alt="station-banner"
                          src={URL.createObjectURL(selectedFile)}
                          sx={{
                            position: "absolute",
                            width: 200,
                            height: 200,
                            top: 0,
                            left: 0,
                            zIndex: 3,
                            borderRadius: "17.96px",
                          }}
                        />
                      )}
                      <CameraImg />
                      <Typography
                        component="span"
                        sx={{
                          textAlign: "center",
                          maxWidth: "133px",
                          lineHeight: "1.2rem",
                          color: "rgba(255, 255, 255, 0.2)",
                        }}
                      >
                        Upload Station Photo
                      </Typography>
                    </Stack>
                  </label>
                </div>

                <div>
                  <FormControl fullWidth sx={{ mb: 1.3 }}>
                    <EightTextField
                      id="station-name"
                      label="Station Name"
                      variant="outlined"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      required={true}
                      autoComplete="off"
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1.3 }}>
                    <EightTextField
                      id="station-about"
                      label="About Station"
                      name="description"
                      required={true}
                      multiline
                      rows={4}
                      value={formValues.description}
                      placeholder="Tell us more about station"
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1.3 }}>
                    <Autocomplete
                      className="gradient-tags"
                      multiple
                      id="tags-filled"
                      options={[]}
                      defaultValue={[]}
                      freeSolo
                      onChange={(e, value) => setTags((state) => value)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <Chip
                              key={index}
                              label={option}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                      renderInput={(params) => (
                        <EightTextField
                          {...params}
                          label="Tags"
                          placeholder="Add tags"
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 1.3 }}>
                    <FormGroup className="mt-18 mb-35">
                      <FormControlLabel
                        control={
                          <EightSwitch
                            sx={{ m: 1 }}
                            handleChange={handlePvtStn}
                          />
                        }
                        label={<PrivateStationLabel />}
                        labelPlacement="start"
                        value="1"
                        className="switch-label"
                      />
                    </FormGroup>
                  </FormControl>

                  {!isPrivate && (
                    <Fragment>
                      <FormControl fullWidth sx={{ my: 1.3 }}>
                        <FormGroup className="mt-18 mb-35">
                          <FormControlLabel
                            control={
                              <EightSwitch
                                sx={{ m: 1 }}
                                handleChange={handleSchedular}
                              />
                            }
                            label={<ScheduleStationLabel />}
                            labelPlacement="start"
                            value="1"
                            className="switch-label"
                          />
                        </FormGroup>
                      </FormControl>
                      {isScheduled === "true" && (
                        <Schedular
                          scheduledOn={scheduledOn}
                          handleScheduledOn={handleScheduledOn}
                          handleInputChange={handleInputChange}
                          aboutSchedule={formValues.aboutSchedule}
                        />
                      )}
                    </Fragment>
                  )}

                  <Stack
                    my={3}
                    gap={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      className="bttn-grey ht-56 ft-16 width-100"
                      type="submit"
                      disabled={isSubmitted}
                      onClick={() => {
                        setButtonType(1);
                      }}
                    >
                      <span className="gradient-text">Save Station</span>
                    </Button>
                    <Button
                      className="bttn-gradient ft-16 width-100 ht-56"
                      type="submit"
                      disabled={isSubmitted}
                      onClick={() => {
                        setButtonType(2);
                      }}
                    >
                      Go Live
                    </Button>
                  </Stack>
                </div>
              </Stack>
            </FormControl>
          </form>
        </CustomScroll>
      </div>
    </Dialog>
  );
};

export default StationFormDialog;
