import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import secureLocalStorage from "react-secure-storage";
class firebaseStorage {
  constructor() {
    this._config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    initializeApp(this._config);
    this._user = secureLocalStorage.getItem("user") || {};
  }
  async fetchFirebaseToken() {
    const response = await fetch(
      `${process.env.REACT_APP_FIREBASE_BASE_URL}/generate_token`,
      {
        method: "POST",
        body: JSON.stringify({ id: this._user.id }),
      }
    );

    const data = await response.json();
    this._authToken = data.token;
  }

  async sendToFirebaseStorage(data, id) {
    const auth = getAuth();

    const storage = getStorage();
    const storageRef = ref(storage, `avatar/${id}`);
    try {
      await this.fetchFirebaseToken();
      await signInWithCustomToken(auth, this._authToken);
    } catch (e) {
      console.log("error adding token: ", e);
    }
    const metadata = {
      contentType: "image/jpeg",
    };
    return new Promise((resolve, reject) => {
      uploadBytes(storageRef, data, metadata)
        .then(async (snapshot) => {
          const url = await getDownloadURL(snapshot.ref);
          resolve(url);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default firebaseStorage;
