import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";

export const TextField1 = styled(TextField)({
  boxShadow:
    "4.49025px 4.49025px 4.49025px rgba(27, 27, 27, 0.25), -2.24513px -2.24513px 5.61281px rgba(37, 37, 37, 0.25), inset 4.49025px 4.49025px 11.2256px rgba(10, 10, 10, 0.25), inset -6.73538px -6.73538px 11.2256px rgba(49, 48, 48, 0.25)",
  borderRadius: "112.256px",
  background: "#212121",
  borderColor: "transparent",
  outline: "none",
  "& label": {
    color: "rgba(255, 255, 255, 0.2)",
  },
  "& .MuiInputBase-input ": {
    color: "#fff",
    padding: "17.4px 11px 17.4px 20px",
  },
});

export function PhoneCodeTextField(props) {
  return (
    <Paper
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        color: "#fff",
        boxShadow:
          "4.49025px 4.49025px 4.49025px rgba(27, 27, 27, 0.25), -2.24513px -2.24513px 5.61281px rgba(37, 37, 37, 0.25), inset 4.49025px 4.49025px 11.2256px rgba(10, 10, 10, 0.25), inset -6.73538px -6.73538px 11.2256px rgba(49, 48, 48, 0.25)",
        borderRadius: "112.256px",
        background: "#212121",
      }}
    >
      <div style={{ padding: "0 10px 0 24px", fontSize: "20px" }}>+91</div>
      <Divider
        sx={{
          width: "2.38px",
          height: "25.26px",
          borderRadius: "5.6px",
          m: 0.5,
          background:
            "linear-gradient(257.44deg, #F27121 0%, #E94057 49.32%, #8A2387 100%)",
        }}
        orientation="vertical"
      />
      <InputBase
        {...props}
        sx={{
          ml: 1,
          flex: 1,
          color: "#fff",
          padding: "12px 13px",
          fontSize: "20px",
        }}
      />
    </Paper>
  );
}
