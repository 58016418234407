import { Switch, Route, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import DashboardPage from "../pages/DashboardPage";
import ProfilePage from "../pages/ProfilePage";
// import StationDetailPage from "../pages/StationDetailPage";
const SessionPage = loadable(() => import("../pages/SessionPage"));

const PrivateRoute = () => {
  return (
    <Switch>
      <Route exact path="/">
        <DashboardPage />
      </Route>
      <Route path="/session">
        <SessionPage />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      {/* <Route path="/profile/station/detail/:id">
        <StationDetailPage />
      </Route> */}
      {/* <Route path="/chat" exact>
                    <ChatPage />
                  </Route> */}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PrivateRoute;
