import loading from '../../assets/images/loading.json';
import Backdrop from '@mui/material/Backdrop';
import create from 'zustand';
import Lottie from 'lottie-react';

const useStore = create((set) => ({
  open: false,
}));

export const eightBackdrop = (open) => {
  useStore.setState({ open });
};

export default function EightBackdrop() {
  const { open } = useStore();

  return (
    <div>
      <Backdrop
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 999,
        }}
        open={open}
      >
        <Lottie animationData={loading} style={{ height: 400 }} loop autoplay></Lottie>
      </Backdrop>
    </div>
  );
}
