import loading from '../../assets/images/loader.json';
import Lottie from 'lottie-react';
import { Stack } from '@mui/material';

export default function EightLoader() {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{height: '100%'}}>
      <Lottie
        animationData={loading}
        style={{ width: '100%', height: '131px' }}
        loop
        autoplay
      ></Lottie>
    </Stack>
  );
}
