import create from 'zustand';
import { Avatar, Stack, Button, Dialog, FormGroup } from '@mui/material';
import { useContext, useState } from 'react';
import AuthContext from '../../store/auth-context';
import Schedular from '../schedular';
import useGlobalStore from "../../hooks/useGlobalStore";

const useStore = create((set) => ({
  dialog: false,
  station: null,
}));

export const scheduledStationDialog = (dialog, station) => {
  useStore.setState({ dialog, station });
};

const ScheduledStationDialog = () => {
  const { dialog, station } = useStore();
  const [editScheduled, setEditScheduled] = useState(false);
  const [scheduledOn, setScheduledOn] = useState(0);
  const [aboutValue, setAboutValue] = useState();
  const [isScheduled, setIsScheduled] = useState('false');
  const authCtx = useContext(AuthContext);

    
  const changeUpdated = useGlobalStore((state) => state.changeUpdated);

  const closeHandler = () => {
    useStore.setState({ dialog: false });
    changeUpdated();
  };

  const handleInputChange = (e) => {
    setAboutValue(e.target.value);
  };

  const handleEditSchedule = () => {
    setEditScheduled(true);
    setAboutValue(station.aboutSchedule);
    setScheduledOn(new Date(station.scheduledOn));
    setIsScheduled('true');
  };

  const handleScheduledOn = (newValue) => {
    setScheduledOn(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_AWS_BASE_URL}/api/station/${station.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify({
        aboutSchedule: aboutValue,
        scheduledOn: scheduledOn.getTime(),
        isScheduled: isScheduled,
      }),
    });
    closeHandler();
  };

  const handleDeleteSchedule = (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_AWS_BASE_URL}/api/station/${station.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify({
        aboutSchedule: ' ',
        scheduledOn: 0,
        isScheduled: 'false',
      }),
    });
    closeHandler();
  };

  return (
    <Dialog open={dialog} onClose={closeHandler} maxWidth="sm" fullWidth>
      {station && (
        <Stack style={{ padding: '60px 95px' }} direction="column" alignItems="center" gap={1}>
          <Stack direction="column" alignItems="center">
            <Stack style={{ position: 'relative', width: 200, height: 200 }}>
              <Avatar
                sx={{
                  width: 200,
                  height: 200,
                  background: '#212121',
                  boxShadow:
                    '5.61297px 5.61297px 13.4711px rgba(0, 0, 0, 0.25), -5.61297px -5.61297px 11.2259px rgba(51, 51, 51, 0.25), inset -3.36778px -3.36778px 11.2259px rgba(49, 49, 49, 0.25)',
                  borderRadius: '17.9615px',
                }}
                alt={station.name}
                src={station.banner}
              />
              <div className="eight-group-badge inner clr-white m-p-24-10">
                <div>
                  <span className="icon-pg mr-6"></span>
                  {station.owner.followers}
                </div>
              </div>
            </Stack>
            <h3 className="ft-18 fw-700 mt-8">{station.name}</h3>
          </Stack>
          {editScheduled ? (
            <form className="mt-18 mb-35 width-100" onSubmit={handleSubmit}>
              <FormGroup>
                <Schedular
                  scheduledOn={new Date(station.scheduledOn)}
                  aboutSchedule={aboutValue}
                  handleScheduledOn={handleScheduledOn}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
              <Button className="bttn-gradient pd-l-r-30 ft-18 ht-56" type="submit">
                Save Station
              </Button>
            </form>
          ) : (
            <form className="mt-18 mb-35 width-100">
              <Stack my={3} gap={3} direction="row" justifyContent="center" alignItems="center">
                <Button className="bttn-grey ft-16 fw-500 width-100" onClick={handleEditSchedule}>
                  Edit Schedule
                </Button>
                <Button className="bttn-grey ft-16 fw-500 width-100" onClick={handleDeleteSchedule}>
                  <span className="gradient-text">Delete Schedule</span>
                </Button>
              </Stack>
            </form>
          )}
        </Stack>
      )}
    </Dialog>
  );
};

export default ScheduledStationDialog;
