import { Fragment, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import useHttp from '../../hooks/use-http';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import FollowButton from '../../components/UI/Button/FollowButton';

const FetchUserDetail3 = (props) => {
  const [user, setUser] = useState(null);
  const { sendRequest } = useHttp(); //, isLoading, error

  useEffect(() => {
    sendRequest(
      {
        url: `${process.env.REACT_APP_AWS_BASE_URL}/api/user/${props.userId}`,
      },
      (data) => {
        setUser(data);
      }
    );
  }, [sendRequest, props.userId]);

  const setFollower = (followers) => {
    setUser((prevState) => ({
      ...prevState,
      followers,
    }));
  };

  return (
    <Fragment>
      {user && (
        <Stack className="user-detail-header1 lt-grey-1">
          <Stack direction="column" alignItems="center" className="align-center" gap={3}>
            <Avatar sx={{ width: 140, height: 140 }} alt={user.firstName} src={user.avatar} />
            <h2>MJ {user.firstName}</h2>
            <p className="grey-lt-1 width-346 pd-l-r-16">{user.bio}</p>
            <Stack
              sx={{ backgroundColor: '#2B2B2B', borderRadius: '16px', padding: '1.12rem 3.34rem' }}
              direction="row"
              divider={<Divider sx={{ borderColor: '#3C3C3C' }} orientation="vertical" flexItem />}
              spacing={2}
            >
              <div className="ft-14 lt-grey-1">
                <h4 className="mb-5">{user.followers}</h4>{' '}
                {+user.followers > 1 ? 'Followers' : 'Follower'}
              </div>
              <div className="ft-14 lt-grey-1">
                <h4 className="mb-5">{user.following}</h4> Following
              </div>
            </Stack>
            <FollowButton
              userId={user.id}
              isFollowing={user.isFollowing}
              count={user.followers}
              setFollower={setFollower}
              cssStyle={{ height: '56px' }}
            />
          </Stack>
        </Stack>
      )}
    </Fragment>
  );
};

export default FetchUserDetail3;
