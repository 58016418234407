import { Fragment, useContext } from "react";
import FetchStationList from "../api/station/FetchStationList";
import Button from "@mui/material/Button";
import AuthContext from "../store/auth-context";
import StationFormDialog, {
  stationFormDialog,
} from "../components/Dialog/StationFormDialog";
import MainHeader from "../components/Navigation/MainHeader";

const DashboardPage = () => {
  const authCtx = useContext(AuthContext);

  return (
    <Fragment>
      <MainHeader />
      <div className="home-page-container">
        <div className="box-1-layout">
          <h1>Do the talking, while we get you the reach</h1>
          <Button
            className="create-station-bttn bttn-gradient ft-16 width-100 ht-56 mt-50"
            onClick={() => {
              stationFormDialog(true);
            }}
            autoFocus
          >
            Create Station
          </Button>
        </div>
        <div>
          <FetchStationList
            slideSetting={{
              responsive: {
                mobile: {
                  breakpoint: { max: 3000, min: 0 },
                  partialVisibilityGutter: 0,
                  slidesToSlide: 3,
                  items: 3,
                },
              },
              arrows: false,
            }}
            userId={authCtx.user.id}
          />
        </div>
      </div>
      <StationFormDialog />
    </Fragment>
  );
};

export default DashboardPage;
