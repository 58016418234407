import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} onChange={props.onChange} />
))(({ theme }) => ({
  'width': 77,
  'height': 44,
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 2,
    'margin': 2,
    'transitionDuration': '300ms',
    '&.Mui-checked': {
      'transform': 'translateX(35px)',
      'color': '#fff',
      '.MuiSwitch-thumb' : {
        background: '#fff'
      },
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(257.44deg, #F27121 0%, #E94057 49.32%, #8A2387 100%)',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#fff',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#8B8D8E',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 35.2,
    height: 35.2,
    background: '#8B8D8E',
    boxShadow: '4.399999618530273px 8.799999237060547px 4.399999618530273px 0px #00000033'
  },
  '& .MuiSwitch-track': {
    borderRadius: 19,
    backgroundColor: '#3B3B3B',
    boxShadow: '5.5px 5.5px 13.2px rgba(0, 0, 0, 0.25), -5.5px -5.5px 11px rgba(51, 51, 51, 0.25), inset -3.3px -3.3px 11px rgba(49, 49, 49, 0.25)',
    opacity: 1,
    transition: theme.transitions.create(['background'], {
      duration: 500,
    }),
  },
}));
const EightSwitch = (props) => {
  return (
    <IOSSwitch sx={{ m: 1 }} onChange={props.handleChange} />
  )
}

export default EightSwitch;
