import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../store/auth-context";
import { useHistory } from "react-router-dom";
import AuthCode from "react-auth-code-input";
import { Button, FormControl, Grid, Link, Stack } from "@mui/material";
import {
  PhoneCodeTextField,
  TextField1,
} from "../components/Mui/EightComponents";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as AsteriskImg } from "../assets/images/asterisk.svg";

const LoginForm = () => {
  // Inputs
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [didResend, setDidResend] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState("STEP1");
  const [seconds, setSeconds] = useState(60);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    if (step === "STEP2") {
      const interval = setInterval(() => {
        setSeconds((prev) => {
          return prev > 0 ? prev - 1 : 0;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [step]);

  const resendOtp = async () => {
    setDidResend(true);

    await sendOtp(phoneNumber);
  };

  const sendOtp = (phone) =>
    fetch(`${process.env.REACT_APP_EIGHT_APIS_1_BASE_URL}/api/send/otp`, {
      method: "POST",
      body: JSON.stringify({
        phone,
      }),
    });

  // Sent OTP
  const requestOtp = async (e) => {
    e.preventDefault();
    if (phoneNumber === "" || phoneNumber.length < 10) return;
    setStep("STEP2");
    // const resp = await sendOtp(phoneNumber);

    // if (resp.status === 204) {
    //   setStep("STEP2");
    // }
  };

  const validateOtp = async (e) => {
    e.preventDefault();
    if (otp === null) return;

    const resp = await fetch(
      `${process.env.REACT_APP_EIGHT_APIS_1_BASE_URL}/api/verify/otp`,
      {
        method: "POST",
        body: JSON.stringify({
          otp: otp.toString(),
          phone: `+91${phoneNumber}`,
        }),
      }
    );

    const data = await resp.json();

    if (resp.status === 400) {
      alert(data.message);
      return;
    }

    if (data.isRegistered) {
      authCtx.login(data.user);
      history.replace("/");

      return;
    }

    setUser(data);
    setStep("STEP3");
  };

  const onSubmit = async (data) => {
    console.log(user); // @todo
    const response = await fetch(
      `${process.env.REACT_APP_AWS_BASE_URL}/api/user`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );

    if (response.status === 204) {
      authCtx.login({ ...data, ...user });
      history.replace("/");
    }
  };

  return (
    <Fragment>
      {step === "STEP1" && (
        <div>
          <h1 className="mb-18">More than one way to stream</h1>
          <h2 className="mb-25">Introducing Eight Studio.</h2>
          <p className="ft-md lt-grey-1 mb-30">
            You can now start your own radio station, go on-air and engage with
            your fans right from your desktop.
          </p>
          <form onSubmit={requestOtp}>
            <PhoneCodeTextField
              type="tel"
              inputProps={{
                maxLength: 10,
                pattern: "[1-9]{1}[0-9]{9}",
                required: true,
              }}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder="Phone number"
              autoComplete="off"
            />
            <Button
              className="bttn-gradient width-100 mt-20 ft-20 fw-700 ht-60"
              type="submit"
            >
              Sign up / Login
            </Button>
          </form>
        </div>
      )}

      {step === "STEP2" && (
        <div>
          <h1 className="mb-18">Let's get</h1>
          <h1 className="mb-25">you verified.</h1>
          <p className="ft-md lt-grey-1 mb-30">
            Enter the 6 digit OTP we just sent you on +91{phoneNumber} via SMS.
          </p>
          <form onSubmit={validateOtp}>
            {
              <AuthCode
                allowedCharacters="numeric"
                length={6}
                containerClassName="eight-otp-wrp"
                onChange={(val) => {
                  setOtp(+val);
                }}
              />
            }
            {seconds > 0 && (
              <p className="lt-grey-1">Resend Code in {seconds}s</p>
            )}
            {seconds === 0 && !didResend && (
              <Link
                underline="hover"
                sx={{ cursor: "pointer" }}
                onClick={resendOtp}
              >
                Resend Code
              </Link>
            )}
            {didResend && (
              <p className="lt-grey-1">Verification code sent successfully</p>
            )}
            <Button
              type="submit"
              className="bttn-gradient width-100 mt-20 ft-20 fw-700 ht-60"
            >
              Continue
            </Button>
          </form>
        </div>
      )}

      {step === "STEP3" && (
        <div>
          <p className="ft-md lt-grey-1 mb-10">Moving on...</p>
          <h1 className="mb-30">What's your Name?</h1>
          <div>
            <form className="mb-100" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ marginBottom: "10px" }}
                    >
                      <label className="ml-20">First Name</label>
                      <AsteriskImg className="mr-20" />
                    </Stack>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField1
                          {...field}
                          placeholder="Enter first name"
                          autoComplete="off"
                          required
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Stack
                      direction="row"
                      alignContent="space-between"
                      alignItems="center"
                      sx={{ marginBottom: "10px" }}
                    >
                      <label className="ml-20">Last Name</label>
                    </Stack>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <TextField1
                          {...field}
                          placeholder="Enter last name"
                          autoComplete="off"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                className="bttn-gradient width-100 mt-100 ft-20 fw-700 ht-60"
                type="submit"
              >
                Get Started
              </Button>
            </form>
          </div>
        </div>
      )}
      <div id="eight-recaptcha-container"></div>
    </Fragment>
  );
};

export default LoginForm;
