import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DeviceDetectDialog = () => {
  return (
    <Dialog
      open={true}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 }, 'background': '#2A2A2A' }}
      maxWidth="xs"
      keepMounted
    >
      <DialogContent>
        <DialogContentText sx={{ color: '#fff' }}>
          Eight Studio is only available on laptops & Desktops. Request you to login through these
          devices.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button component="a" href="https://www.eight.network/" sx={{ pl: 6 }} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceDetectDialog;
