import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Fragment } from 'react';
import FormControl from '@mui/material/FormControl';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import Stack from '@mui/material/Stack';
import { EightTextField, theme } from './Dialog/StationFormDialog';
import { ThemeProvider } from '@mui/material';

const Schedular = (params) => {
  return (
    <Fragment>
      <FormControl fullWidth sx={{ my: 1.5 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <ThemeProvider theme={theme}>
              <MobileDateTimePicker
                value={params.scheduledOn}
                onChange={(newValue) => {
                  params.handleScheduledOn(newValue);
                }}
                renderInput={(params) => <EightTextField {...params} />}
                minDateTime={new Date()}
              />
            </ThemeProvider>
          </Stack>
        </LocalizationProvider>
      </FormControl>
      <FormControl fullWidth sx={{ my: 1.5 }}>
        <EightTextField
          id="schedule-about"
          label="About Scheduled station"
          placeholder="Tell us more about your scheduled station"
          multiline
          rows={4}
          name="aboutSchedule"
          value={params.aboutSchedule}
          onChange={params.handleInputChange}
        />
      </FormControl>
    </Fragment>
  );
};

export default Schedular;
