import { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import useHttp from "../../hooks/use-http";
import Divider from "@mui/material/Divider";
import { followSlide } from "../../components/Slide/FollowSlide";
import UserEditDialog from "../../components/Dialog/UserEditDialog";
import useGlobalStore from "../../hooks/useGlobalStore";
import EightProfileEditBadge from "../../components/Mui/EightProfileEditBadge";

const FetchUserDetail1 = (props) => {
  const [user, setUser] = useState(null);
  const { sendRequest } = useHttp(); //, isLoading, error
  const isUpdated = useGlobalStore((state) => state.isUpdated);

  useEffect(() => {
    sendRequest(
      {
        url: `${process.env.REACT_APP_AWS_BASE_URL}/api/user/${props.userId}`,
      },
      (data) => {
        setUser(data);
      }
    );
  }, [sendRequest, props.userId, isUpdated]);

  return (
    <Fragment>
      {user ? (
        <Stack className="user-detail-header1 lt-grey-1">
          <Stack
            direction="column"
            alignItems="center"
            className="align-center"
            gap={3}
            sx={{ paddingTop: "2.78rem" }}
          >
            <EightProfileEditBadge user={user} />
            <h2>MJ {user.firstName}</h2>
            <p className="grey-lt-1 width-346 pd-l-r-16">{user.bio}</p>
            {/* <span>{user.phone}</span> */}
            <Stack
              sx={{
                backgroundColor: "#2B2B2B",
                borderRadius: "16px",
                padding: "1.12rem 3.34rem",
              }}
              direction="row"
              divider={
                <Divider
                  sx={{ borderColor: "#3C3C3C" }}
                  orientation="vertical"
                  flexItem
                />
              }
              spacing={2}
            >
              <button
                onClick={() => {
                  followSlide(true, 0, user.id);
                }}
                className="bttn-transparent ft-14 lt-grey-1"
              >
                <h4 className="mb-5">{user.followers}</h4>{" "}
                {+user.followers > 1 ? "Followers" : "Follower"}
              </button>
              <button
                onClick={() => {
                  followSlide(true, 1, user.id);
                }}
                className="bttn-transparent ft-14 lt-grey-1"
              >
                <h4 className="mb-5">{user.following}</h4> Following
              </button>
            </Stack>
          </Stack>
          <UserEditDialog
            firstName={user.firstName}
            lastName={user.lastName}
            bio={user.bio}
            alias={user.alias}
            avatar={user.avatar}
            id={user.id}
          />
        </Stack>
      ) : (
        <Stack
          justifyContent="center"
          alignItems="center"
          className="user-detail-header1 lt-grey-1"
        >
          <div>Loading...</div>
        </Stack>
      )}
    </Fragment>
  );
};

export default FetchUserDetail1;
