import Button from '@mui/material/Button';
import { useState } from 'react';
import useHttp from '../../../hooks/use-http';

const FollowButton = ({ isFollowing, userId, count, setFollower, cssStyle }) => {
  const { sendRequest } = useHttp(); //, isLoading, error
  const [follow, setFollow] = useState({
    isFollowing,
    userId,
    count,
  });

  const followHandler = async (e) => {
    sendRequest(
      {
        url: `${process.env.REACT_APP_AWS_BASE_URL}/api/user/${e.target.getAttribute(
          'data-value'
        )}`,
        method: 'POST',
        body: {
          id: userId,
        },
      },
      (data) => {
        setFollower(data.followers);
        setFollow((prevState) => ({
          ...prevState,
          count: data.followers,
          isFollowing: !follow.isFollowing,
        }));
      }
    );
  };

  return (
    <Button
      className="bttn-gradient"
      style={{
        fontWeight: 500,
        fontSize: '1rem',
        width: '100%',
        maxWidth: '268px',
        ...cssStyle,
      }}
      data-value={follow.isFollowing ? 'unfollow' : 'follow'}
      onClick={followHandler}
      autoFocus
    >
      {follow.isFollowing ? 'Unfollow' : 'Follow'}
    </Button>
  );
};

export default FollowButton;
