import { Switch, Route, Redirect } from "react-router-dom";
import AuthPage from "../pages/AuthPage";

const PublicRoute = () => {
  return (
    <Switch>
      <Route exact path="/">
        <AuthPage />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PublicRoute;
