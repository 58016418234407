import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { ReactComponent as RightArrowImg } from '../../assets/images/right-arrow.svg';
import { ReactComponent as LeftArrowImg } from '../../assets/images/left-arrow.svg';

const SliderButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    background: 'rgb(70 70 70)',
  },
}));

const EightCarouselButton = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, slidesToShow, totalItems },
  } = rest;

  return (
    <div className="carousel-button-group">
      <SliderButton
        className={currentSlide === 0 ? 'prev-bttn disable' : 'prev-bttn'}
        onClick={() => previous()}
        sx={{
          borderRadius: 50,
          background: '#212121',
          width: 52,
          height: 52,
          top: '50%',
          transform: 'translate(0,-50%)',
          left: 0,
          zIndex: 1,
          position: 'absolute',
          boxShadow:
            '5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25), inset -3px -3px 10px rgba(49, 49, 49, 0.25)',
        }}
      >
        <LeftArrowImg />
      </SliderButton>
      <SliderButton
        onClick={() => next()}
        className={currentSlide + slidesToShow === totalItems ? 'next-bttn disable' : 'next-bttn'}
        sx={{
          borderRadius: 50,
          background: '#212121',
          width: 52,
          height: 52,
          top: '50%',
          transform: 'translate(0,-50%)',
          right: 0,
          position: 'absolute',
          boxShadow:
            '5px 5px 12px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(51, 51, 51, 0.25), inset -3px -3px 10px rgba(49, 49, 49, 0.25)',
        }}
      >
        <RightArrowImg />
      </SliderButton>
    </div>
  );
};

export default EightCarouselButton;
