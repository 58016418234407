import { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import { NavLink } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Stack from "@mui/material/Stack";
import eightImg from "../../assets/images/eight_icon.svg";

const MainHeader = () => {
  const authCtx = useContext(AuthContext);

  return (
    <Stack
      component="header"
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <NavLink to="/">
        <Avatar
          sx={{ width: 101, height: 61 }}
          alt="Eight Studio"
          src={eightImg}
        />
      </NavLink>

      {authCtx.isLoggedIn && (
        <NavLink to="/profile">
          <Avatar
            sx={{ width: 56, height: 56 }}
            alt="{authCtx.user.firstName}"
            title="Goto profile"
            src={authCtx.user.avatar}
          />
        </NavLink>
      )}
    </Stack>
  );
};

export default MainHeader;
