import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import create from "zustand";
import CustomScroll from "react-custom-scroll";
import GoLiveButton from "../UI/Button/GoLiveButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EightSwitch from "../Mui/EightSwitch";
import { Fragment, useContext, useState } from "react";
import AuthContext from "../../store/auth-context";
import StationEditDialog, { stationEditDialog } from "./StationEditDialog";
import Schedular from "../schedular";
import FormControl from "@mui/material/FormControl";
import useGlobalStore from "../../hooks/useGlobalStore";

const useGoLiveStore = create((set) => ({
  dialog: false,
  station: null,
}));

export const goLiveDialog = (dialog, station) => {
  useGoLiveStore.setState({ dialog, station });
};

const GoLiveDialog = () => {
  const [editScheduled, setEditScheduled] = useState(false);
  const [scheduledOn, setScheduledOn] = useState(0);
  const [aboutValue, setAboutValue] = useState();
  const [isScheduled, setIsScheduled] = useState("false");
  const { station } = useGoLiveStore();
  const authCtx = useContext(AuthContext);

  const changeUpdated = useGlobalStore((state) => state.changeUpdated);

  const closeHandler = () => {
    useGoLiveStore.setState({ dialog: false });
    if (isScheduled === "true") {
      setIsScheduled("false");
      setScheduledOn(0);
      setAboutValue(" ");
    }
    changeUpdated();
  };

  const handleInputChange = (e) => {
    setAboutValue(e.target.value);
  };

  const handleEditSchedule = () => {
    setEditScheduled(true);
    setAboutValue(station.aboutSchedule);
    setScheduledOn(new Date(scheduledOn));
    setIsScheduled("true");
  };

  const handleScheduledOn = (newValue) => {
    setScheduledOn(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_AWS_BASE_URL}/api/station/${station.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify({
        aboutSchedule: aboutValue,
        scheduledOn: scheduledOn.getTime(),
        isScheduled: isScheduled,
      }),
    });
    closeHandler();
  };

  const handleDeleteSchedule = (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_AWS_BASE_URL}/api/station/${station.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authCtx.token}`,
      },
      body: JSON.stringify({
        aboutSchedule: " ",
        scheduledOn: 0,
        isScheduled: "false",
      }),
    });
    closeHandler();
  };

  const handleSchedular = () => {
    if (isScheduled === "true") {
      setIsScheduled("false");
      setScheduledOn(0);
      setAboutValue(" ");
    }
    if (isScheduled === "false") {
      setIsScheduled("true");
      setScheduledOn(new Date());
    }
  };

  return (
    <Dialog
      open={useGoLiveStore.getState().dialog}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      {station && (
        <div className="golive-dialog-wrapper">
          <div
            className="station-banner"
            style={{ backgroundImage: `url(${station.banner})` }}
          >
            <div className="eight-group-badge clr-white m-p-24-10">
              <div>
                <span className="icon-pg mr-6"></span>
                {station.owner.followers}
              </div>
              <div className="separator"></div>
              <div>{station.isPrivate ? "Private" : "Public"} Station</div>
            </div>
          </div>
          <div className="station-description">
            <CustomScroll
              heightRelativeToParent="calc(100%)"
              keepAtBottom={true}
            >
              {station.owner.id === authCtx.user.id && (
                <div className="flex-end-wrp mb-20">
                  <Button
                    className="bttn-grey ft-18 bttn-normal"
                    onClick={() => {
                      stationEditDialog(true);
                    }}
                  >
                    <span className="gradient-text">Edit</span>
                  </Button>
                  <StationEditDialog
                    name={station.name}
                    description={station.description}
                    aboutSchedule={station.aboutSchedule}
                    isScheduled={station.isScheduled}
                    isPrivate={station.isPrivate}
                    scheduledOn={station.scheduledOn}
                    tags={station.tags}
                    id={station.id}
                    banner={station.banner}
                  />
                </div>
              )}
              <h2>{station.name}</h2>
              <p className="grey-lt-1 mt-20 mb-25">{station.description}</p>
              <Stack direction="row" gap="12px" flexWrap="wrap">
                {station.tags &&
                  station.tags.map((tag, index) => (
                    <Chip key={index} label={tag} />
                  ))}
              </Stack>

              {!station.isPrivate &&
                (station.isScheduled === "true" ? (
                  <>
                    {editScheduled ? (
                      <>
                        <form onSubmit={handleSubmit}>
                          <Fragment>
                            <FormGroup className="mt-18 mb-35">
                              <Schedular
                                scheduledOn={new Date(station.scheduledOn)}
                                aboutSchedule={aboutValue}
                                handleScheduledOn={handleScheduledOn}
                                handleInputChange={handleInputChange}
                              />
                            </FormGroup>
                          </Fragment>
                          <FormControl
                            fullWidth
                            sx={{ my: 1.3, alignItems: "end" }}
                          >
                            <button
                              variant="contained"
                              className="bttn-grey width-425 ht-56 fw-500 ft-18 bttn-grey"
                              type="submit"
                            >
                              <span className="gradient-text">
                                Save Station
                              </span>
                            </button>
                          </FormControl>
                        </form>
                      </>
                    ) : (
                      <>
                        <Stack
                          p={2}
                          sx={{
                            color: "rgba(255, 255, 255, 0.5)",
                            fontSize: "1rem",
                            fontWeight: 600,
                            textAlign: "center",
                            margin: "35px auto",
                          }}
                        >
                          Station is already scheduled
                        </Stack>
                        {station.owner.id === authCtx.user.id && (
                          <form>
                            <Stack
                              my={3}
                              gap={3}
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Button
                                className="bttn-grey ft-16 fw-500 width-100"
                                onClick={handleEditSchedule}
                              >
                                Edit Schedule
                              </Button>
                              <Button
                                className="bttn-grey ft-16 fw-500 width-100"
                                onClick={handleDeleteSchedule}
                              >
                                <span className="gradient-text">
                                  Delete Schedule
                                </span>
                              </Button>
                            </Stack>
                          </form>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {station.owner.id === authCtx.user.id && (
                      <Fragment>
                        <FormGroup className="mt-18 mb-35">
                          <FormControlLabel
                            control={
                              <EightSwitch
                                sx={{ m: 1 }}
                                handleChange={handleSchedular}
                              />
                            }
                            label="Do you want to schedule the station"
                            labelPlacement="start"
                            value="1"
                            className="switch-label"
                          />
                        </FormGroup>
                        {isScheduled === "true" && (
                          <>
                            <form onSubmit={handleSubmit}>
                              <Schedular
                                scheduledOn={scheduledOn}
                                handleScheduledOn={handleScheduledOn}
                                handleInputChange={handleInputChange}
                                aboutSchedule={aboutValue}
                              />

                              <FormControl
                                fullWidth
                                sx={{ my: 1.3, alignItems: "end" }}
                              >
                                <button
                                  variant="contained"
                                  className="bttn-grey width-425 ht-56 fw-500 ft-18 bttn-grey"
                                  type="submit"
                                >
                                  <span className="gradient-text">
                                    Save Station
                                  </span>
                                </button>
                              </FormControl>
                            </form>
                          </>
                        )}
                      </Fragment>
                    )}
                  </>
                ))}
              <div className={station.isPrivate ? "mt-35" : ""}>
                <GoLiveButton station={station} />
              </div>
            </CustomScroll>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default GoLiveDialog;
